<template>
  <div class="content-box">
    <el-dialog
      :title="`${type === 'add' ? '添加' : '编辑'}群机器人`"
      :visible="is_show"
      width="500px"
      class="dialog-vertical"
      @close="close()"
      @open="openFn"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="120px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item prop="name" label="机器人名字：">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="机器人类型：">
          <el-select
            v-model="form.robot_type"
            placeholder="请选择"
          >
            <el-option label="企业微信内部群机器人" :value="0"> </el-option>
            <el-option label="钉钉自定义机器人" :value="1"> </el-option>
            <el-option label="飞书自定义机器人" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="webhook" label="webhook链接：">
          <el-input v-model="form.webhook" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="admin_user_messages" label="选择推送信息：">
          <el-select
            v-model="form.admin_user_messages"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in messagesConfig"
              :key="item.msg"
              :label="item.title"
              :value="item.msg"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getMessagesConfig } from '../api/wx-company-config'

export default {
  name: 'EntranceDialog',
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    // 当前row
    currentRow: {
      type: Object,
      required: true,
    },
  },
  components: {},
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open
      },
      set(val) {
        this.$emit('changeOpen', val)
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {},
      rules: {
        name: [
          { required: true, message: '请输入机器人名字', trigger: 'blur' },
        ],
        webhook: [
          { required: true, message: '请输入webhook链接  ', trigger: 'blur' },
        ],
        admin_user_messages: [
          { required: true, message: '请选择推送消息', trigger: 'blur' },
        ],
      },
      loading: false,
      messagesConfig: [],
      type: 'add',
    }
  },
  methods: {
    close() {
      this.is_show = false
      this.$refs['form'].clearValidate()
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form)
          if (this.type === 'add') {
            this.$emit('addRobot', this.form)
          }
          if (this.type === 'edit') {
            this.$emit('editRobot', this.form)
          }
          this.close()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    openFn() {
      this.form = JSON.parse(JSON.stringify(this.$props.currentRow))
      if (this.form.name) {
        this.type = 'edit'
      } else {
        this.type = 'add'
      }
      this.getConfig()
    },
    // 获取推送消息配置
    getConfig() {
      getMessagesConfig().then((res) => {
        this.messagesConfig = res.data
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
