/*
 * @Author: dongjia
 * @Date: 2021-06-28 13:59:07
 * @LastEditTime: 2021-08-02 15:39:49
 * @LastEditors: aleaner
 * @Description: 配置页面接口
 * @FilePath: \saas-admin-vue\src\modules\wx-company\api\wx-company-config.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 获取配置详情
export const getConfig = data => {
  return api({
    url: '/admin/wx_open/settings/getWechatCompanyConfig',
    method: 'post',
    data,
    notCancel: true
  });
};

// 保存配置详情
export const saveConfig = data => {
  return api({
    url: '/admin/wx_open/settings/saveWechatCompanyConfig',
    method: 'post',
    data,
    notCancel: true
  });
};
// 保存配置详情
export const getMessagesConfig = data => {
  return api({
    url: '/admin/wx_open/settings/getAdminUserMessages',
    method: 'post',
    data,
    notCancel: true
  });
};